<template>
  <div>
    <NuxtLayout name="sticky">
      <template #header>
        <component
          :is="config.header"
          v-if="config"
          v-bind="{ onClick: navigateBack }"
        />
      </template>

      <LcTabs
        v-bind="{ tabs, value }"
        adaptive-height
        trigger="manual"
        @update:value="navigateToTab"
      >
        <template #tab-panel="{ tab }">
          <NuxtPage v-bind="{ id, type }" :name="tab.name" />
        </template>
      </LcTabs>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { LcTabs } from '@lottocom/frontend-components'

import {
  CUSTOM_INFO_ROUTES,
  type CustomInfoRoute,
  INFO_ROUTE_TYPES,
  type InfoRouteMetaConfig,
} from '~/constants/infoRoutes'

type InfoTabData = {
  path: CustomInfoRoute
}

const { t } = useI18n()
const router = useRouter()

const config = computed(
  () => (router.currentRoute.value.meta.config as InfoRouteMetaConfig) ?? null,
)

const { activeTabs: tabs } = useTabs<InfoTabData>([
  {
    data: { path: CUSTOM_INFO_ROUTES.Rules },
    name: config.value.routes.Rules,
    title: t('info.tab.rules.title'),
  },
  {
    data: { path: CUSTOM_INFO_ROUTES.Prizes },
    name: config.value.routes.Prizes,
    title: t('info.tab.prizes.title'),
  },
  {
    data: { path: CUSTOM_INFO_ROUTES.Faq },
    name: config.value.routes.Faq,
    title: t('info.tab.faq.title'),
  },
])

const id = computed(() =>
  config.value
    ? String(router.currentRoute.value.params[config.value.param])
    : '',
)
const type = computed(() => config.value?.type)
const value = computed(() => String(router.currentRoute.value.name))

const navigateBack = () => {
  const previousRoute =
    config.value?.type === INFO_ROUTE_TYPES.Lottery
      ? {
          path: `/${router.currentRoute.value.params[config.value.param]}`,
          query: { newLine: '1' },
        }
      : `/${INFO_ROUTE_TYPES.Scratchcard}/${router.currentRoute.value.params[config.value.param]}`

  useRouterUtils().navigateBack({ previousRoute })
}

const navigateToTab = async (name?: string) => {
  if (!name) return

  const path = tabs.value.find((tab) => tab.name === name)?.data.path
  await navigateTo(path, { replace: true })
}
</script>
